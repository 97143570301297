<template>
  <header>
    <div class="menu_part">
      <div :class="[$store.state.edit ? 'menu_white' : 'menu']" @click="$store.state.menu = true">
        <div>
          <svg width="100%" height="100%" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 16H26" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6 9H26" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6 23H26" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </div>
      <a @click.prevent="goBack" class="back" v-if="back">Back</a>

      <div class="header_controls">
        <!-- <div @click="this.$router.push({ path: `/Search/`})" class="save" v-if="this.$route.name !== 'search'" ><img width="40px" src="../../../public/img/search.svg"></div> -->
        <div v-if="!this.$store.state.search && this.windowWidth < 901" class="search" @click="this.$store.state.search = !this.$store.state.search"></div>
        <search v-if="this.$store.state.search || this.windowWidth >= 901 && this.$route.name !== 'Help'"></search>

        <button type="button" class="info" v-if="$store.state.targetPath !== '' && this.$route.name === 'Machine' && this.$store.state.currentlocation" @click.prevent="gogogo"></button>

        <div v-if="this.$route.name == 'Select' || this.$route.name == 'Article' || this.$route.name == 'Section'" @click="collapse" class="collapse">
          <p v-if="windowWidth >= 600">Collapse/Expand All</p>
          <span :style="[this.$store.state.selectCollapse || this.$store.state.bookCollapse || this.$store.state.sectionCollapse ? { 'transform': 'rotate(180deg)' } : { 'transform': 'rotate(0deg)' }]"></span>
        </div>
      </div>
      
    </div>

    <transition name="fade">
      <shade v-if="menu || disabledDialog" @click="$store.state.menu = false; disabledDialog = false;"></shade>
    </transition>


    <disabled v-if="disabledDialog" @closing-event="disabledDialog = false"></disabled>

    <transition name="slide-fade">
      <burger-menu v-if="menu" @disabled-window="disabledDialog = true; $store.state.menu = false"></burger-menu>
    </transition>

  </header>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import disabled from '../dialogs/disabled.vue';

const search = defineAsyncComponent(
  () => import('../search/search.vue'),
);

const burgerMenu = defineAsyncComponent(
  () => import('../menu/burger_menu.vue'),
);

const shade = defineAsyncComponent(
  () => import('../shade/shade.vue'),
);

export default {
  name: 'topBar',
  components: {
    burgerMenu,
    shade,
    search,
    disabled,
  },
  data() {
    return {
      disabledDialog: this.$store.state.disabledDialog,
      search: false,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    };
  },
  computed: {
    menu() {
      return this.$store.state.menu;
    },
    back() {
      if (this.$route.name !== 'Home' || this.$router.options.history.state.back === '/account/login') {
        return true;
      }
      return false;
    },
  },
  methods: {
    here() {
      console.log('emit worked')
    },
    collapse() {
      if (this.$route.name === 'Select') {
        this.emitter.emit('collapse_select');
      } else if (this.$route.name === 'Article') {
        this.$store.state.bookCollapse = !this.$store.state.bookCollapse;
      } else if (this.$route.name === 'Section') {
        this.$store.state.sectionCollapse = !this.$store.state.sectionCollapse;
      }
    },
    goBack() {
      localStorage.removeItem('letter');
      this.$router.go(-1);
    },
    editorToggle() {
      this.$store.commit('toggle');
    },
    gogogo() {
      // console.log(this.$store.state.currentlocation,'this.$store.state.currentlocation')
      this.$router.push({ path: `/MachineInfo/` + this.$store.state.currentlocation })
    },
  },
  mounted() {
    // if (this.windowWidth < this.windowHeight && this.windowWidth < 600) {
    //   this.searchShown = false;
    // } else if (this.windowWidth > this.windowHeight && this.windowHeight < 600) {
    //   this.searchShown = false;
    // }
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    });
  },
};
</script>

<style lang="scss" scoped>
.collapse{
  height: inherit;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 0;
  p{
    margin-top: 0px;
    margin-bottom: 0px;
    height: inherit;
    line-height: 64px;
    display: inline-block;
    float: left;
    padding-left: 8px;
    padding-right: 5px;
    transform: rotate(0deg);
    color: #787878;
    flex-shrink: 0;
  }
  span{
    transition: all .2s;
    height: inherit;
    line-height: 64px;
    width: 52px;
    display: inline-block;
    background-image: url(../../assets/arrow.svg);
    background-size: 30px;
    background-repeat: no-repeat;
    transform: rotate(180deg);
    background-color: rgba(0,0,0,0) !important;
    flex-shrink: 0;
  }
}
.search{
  transition: all .2s;
  height: inherit;
  line-height: 64px;
  width: 52px;
  display: inline-block;
  background-image: url(../../../public/img/search.svg);
  background-size: 30px;
  background-repeat: no-repeat;
  background-color: rgba(0,0,0,0) !important;
  // @media screen and (min-width: 901px){
  //   display: none;
  // }
}
.back {
  height: inherit;
  line-height: 64px;
  color: #787878;
  padding: 0 20px 0 30px;
  background-repeat: no-repeat;
  position: relative;
  text-indent: 2px;
  cursor: pointer;
  // BIG SCREEN
  // @media screen and (min-width: 27 01px) and (max-width: 3840px){
  //   padding: 0 20px 0 56px;
  // }
  // DESKTOP
  // @media screen and (min-width: 1025px) and (max-width: 27 00px){
  @media screen and (min-width: 1025px){
    padding: 0 20px 0 47px;
  }
  // LAPTOP
  @media screen and (min-width: 901px) and (max-width: 1024px){
    padding: 0 20px 0 44px;
  }
  // TABLET
  @media screen and (min-width: 481px) and (max-width: 900px){
    padding: 0 20px 0 48px;
  }
  // MOBILE
  @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
    padding: 0 20px 0 36px;
  }
}
.back::before{
  content: ' ';
  background-repeat: no-repeat;
  background-image: url(../../../public/img/back.svg);
  display: block;
  position: absolute;
  background-size: contain;
  height: 25px;
  width: 25px;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
  
  // BIG SCREEN
  // @media screen and (min-width: 27 01px) and (max-width: 3840px){
  //   height: 35px;
  //   width: 28px;
  //   left: 28px;
  // }
  // DESKTOP
  // @media screen and (min-width: 1025px) and (max-width: 27 00px){
  @media screen and (min-width: 1025px){
    height: 24px;
    width: 19.2px;
    left: 26px;
  }
  // LAPTOP
  @media screen and (min-width: 901px) and (max-width: 1024px){
    height: 20px;
    width: 16px;
    left: 24px;
  }
  // TABLET
  @media screen and (min-width: 481px) and (max-width: 900px){
    height: 20px;
    width: 20px;
    left: 26px;
  }
  // MOBILE
  @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
    height: 20px;
    width: 16px;
    left: 20px;
  }
}
.back,.collapse p{
  font-size: 1rem;
}
header {
  height: 64px;
  width: 100%;
  background-color: #fff;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  box-shadow: 0px 2px 8px 2px #0000000F;
  box-sizing: border-box;
  // @media screen and (min-width: 27 01px) and (max-width: 3840px){
  //   padding: 0 100px;
  // }
  // DESKTOP
  // @media screen and (min-width: 1025px) and (max-width: 27 00px){
  @media screen and (min-width: 1025px){
    padding: 0 50px;
  }
  // LAPTOP
  @media screen and (min-width: 901px) and (max-width: 1024px){
    padding: 0 40px;
  }
  // TABLET
  @media screen and (min-width: 481px) and (max-width: 900px){
    padding: 0 30px;
    height: 52px;
    .menu_part{
      .back{
        line-height: 52px;
      }
    }
  }
  // MOBILE
  @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
    padding: 0 15px;
  }
  .header_controls {
    height: inherit;
    display: flex;
    margin-left: auto;
    >div {
      height: inherit;
      line-height: 64px;
      font-size: 1rem;
      cursor: pointer;
    }
  }
  @media screen and (max-height: 1024px) and (min-height: 600px) and (max-width: 1366px) and (min-width: 961px) and (orientation: landscape) {
    .collapse{
      p{
        line-height: 64px;
      }
    }
    .header_controls {
      >div{
        line-height: 64px;
        height: inherit;
      }
    }
  }
  @media screen and (max-width: 1024px) and (min-width: 600px) and (max-height: 1370px) and (min-height: 920px) and (orientation: portrait) {
    .collapse{
      p{
        line-height: 64px;
        
      }
    }
    .header_controls {
      >div{
        line-height: 64px;
        height: inherit;
      }
    }
  }
  @media screen and (min-width: 481px) and (max-width: 900px){
    .collapse{
      p{
        line-height: 52px;
        
      }
    }
    .header_controls {
      >div{
        line-height: 52px;
        height: inherit;
      }
    }
  }
  @media screen and (max-width: 599px) and (orientation: portrait),screen and (max-height: 599px) and (orientation: landscape) {
    .collapse{
      width: 32px;
      p{
        display: none;
      }
    }
    .header_controls {
      // right: 120px;
      >div{
        line-height: 44px;
        height: inherit;
      }
    }
  }
  //  @media screen and (max-height: 599px) and (orientation: landscape) {
  //   .collapse{
  //     p{
  //       display: none;
  //     }
  //   }
  //   .header_controls {
  //     right: 120px;
  //     >div{
  //       line-height: 44px;
  //       height: inherit;
  //     }
  //   }
  // }
  .sos,
  .info,
  .search,
  .collapse span{
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    width: 32px;
    border: 0;
    background-color: #fff;
    outline: none;
  }

  .menu_part {
    width: 100%;
    float: right;
    display: flex;
    height: inherit;
  }

  .menu{
    cursor: pointer;
    >div{
      background-color: #fff;
      >svg{
        path{
          stroke: #787878;
        }
      }
    }
  }
  .menu_white{
    cursor: pointer;
    >div{
      background-color: #099F45;
      >svg{
        path{
          stroke: #fff;
        }
      }
    }
  }
  .menu,.menu_white {
    display: flex;
    justify-content: center;
    align-items: center;
    >div{
      transition: all .2s;
      height: 32px;
      width: 32px;
      // BIG SCREEN
      // @media screen and (min-width: 27 01px) and (max-width: 3840px){
      //   height: 50px;
      //   width: 50px;
      // }
      // DESKTOP
      // @media screen and (min-width: 1025px) and (max-width: 27 00px){
      @media screen and (min-width: 1025px){
        height: 50px;
        width: 50px;
      }
      // LAPTOP
      @media screen and (min-width: 901px) and (max-width: 1024px){
        height: 50px;
        width: 50px;
      }
      // TABLET
      @media screen and (min-width: 481px) and (max-width: 900px){
        height: 40px;
        width: 40px;
      }
      // MOBILE
      @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
        height: 32px;
        width: 32px;
      }
      border-radius: 4px;
      >svg{
        path{
          transition: all .2s;
        }
      }
    }
  }
  .info {
    background-image: url(../../../public/img/info.svg);
    height: inherit;
  }
  .sos {
    background-image: url(../../../public/img/sos_disabled.svg);
    height: inherit;
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(-300px);
}

// .slide-updown-enter-active {
//   transition: all 0.3s ease-out;
// }

// .slide-updown-leave-active {
//   transition: all 0.3s ease-out;
// }

// .slide-updown-enter-from,
// .slide-updown-leave-to {
//   margin-top: -50px;
// }
// @media screen and (min-width: 27 01px) and (max-width: 3840px){
//   header {
//     height: 150px;
//     .header_controls{
//       .collapse{
//         p{
//           line-height: 150px;
//           font-size: 36px;
//           padding-right: 20px;
//         }
//         span{
//           width: 60px;
//           background-size: 55px;
//         }
//       }
//       .info{
//         height: 150px;
//         width: 69px;
//         background-size: 100%;
//       }
//     }
//     .menu_part{
//       .back{
//         line-height: 150px;
//         height: 150px;
//         font-size: 36px;
//         padding: 0 20px 0 65px;
//       }
//       .menu,.menu_white{
//         >div{
//           height: 90px;
//           width: 90px;
//           border-radius: 11px;
//         }
//       }
//     }
//   }
// }
@media screen and (max-width: 1024px) and (min-width: 481px) and (max-height: 1366px) and (min-height: 930px) and (orientation: portrait) {
  // header {
  //   height: 64px;
  //   .menu_part{
  //     .back{
  //       line-height: 64px;
  //     }
  //   }
  // }
}
@media screen and (max-width: 480px) and (orientation: portrait),
screen and (max-height: 480px) and (orientation: landscape) {
  header {
    height: 44px;
    .menu_part{
      .back{
        line-height: 44px;
      }
    }
  }
}
@media screen and (max-width: 480px) and (orientation: portrait) {
  header{
    .menu_part{
      .menu,.menu_white{
        height: inherit;
        margin-right: 0px;
      }
      .back{
        height: 44px;
        line-height: 44px;
      }
      .info{
        height: 44px;
      }
    }
  }
}
</style>
